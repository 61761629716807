import React, { useState } from "react";

// Style
import {
  BestPlayerWrap,
  BestPlayersTable,
  BestPlayerContent,
  BestPlayerName,
  BestPlayerValue,
  BestPlayerWrapper,
} from "../../style/TournamentsPage";

import { getPlayerParamMarkers } from "../../helpers";

// Images
import star from "../../images/ic_start.svg";
import emptyState from "../../images/contactLogo.svg";

// Components
import Empty from "../../components/empty/index";
import { NotificationList } from "../notification/NotificationList";
import { useNotification } from "../notification/useNotification";

// Language
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  TabContent,
  TabListWrapper,
  TabsWrapper,
  TabTitle,
  TopList,
} from "../../style/Favorites";

const BestPlayers = ({ table, info }) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [expandedIndices, setExpandedIndices] = useState([]);

  const toggleShowLeads = (index) => {
    setExpandedIndices((prev) =>
      prev.includes(index) ? prev.filter((i) => i !== index) : [...prev, index]
    );
  };

  const excludeKeywords = [
    "Fouls",
    "Cards",
    "Losses",
    "Mistakes",
    "Offsides",
    "GK",
    "Effective Saves",
  ];

  const filteredTable = table.filter(
    (item) =>
      !excludeKeywords.some((keyword) =>
        Object.values(item).some(
          (value) => typeof value === "string" && value.includes(keyword)
        )
      )
  );

  const gkTable = table.filter((item) =>
    Object.values(item).some(
      (value) => typeof value === "string" && value.includes("GK")
    )
  );

  const { notifications, addNotification, removeNotification } =
    useNotification();

  const handleClickPlayerParam = async (item, leader) => {
    const { parameter_id: param_id, parameter_name } = item;
    const { player: { id: player_id, display_name }, value: count } = leader;
    try {
      const controller = new AbortController();
      addNotification(`Fetching clips`, "info", controller);
      document.body.classList.add("loading-cursor");
      const data = await getPlayerParamMarkers(
        player_id,
        param_id,
        info?.year_season?.id ?? '',
        controller
      );
      if (data?.results?.length > 0) {
        const markers = data.results.map((item) => item.id).join(",");
        window.open(`/tournaments/match/video?title=${parameter_name} - ${display_name}&markers=${markers}`);
      } else {
        addNotification(
          `There are 0 clips in the response from the server.`,
          "warning"
        );
      }
    } catch (e) {
      console.log("error get param markers", e);
      if (e.type === "canceled") {
        addNotification(
          `The request to receive ${count} clips of the player has been canceled.`,
          "warning"
        );
        return;
      }
      addNotification(`Error in receiving ${count} player clips.`, "error");
    } finally {
      document.body.classList.remove("loading-cursor");
    }
  };

  const isCanClickPlayerStat = (leader, item) => {
    return (
      item.leaders?.count === 0 ||
      item.parameter_name.includes("%") ||
      item.parameter_name.includes("Mins Played")
    );
  };

  return (
    <>
      <TabsWrapper>
        <TopList>
          <TabListWrapper>
            <TabTitle>players</TabTitle>
            <TabTitle>goalkeepers</TabTitle>
          </TabListWrapper>
        </TopList>
        <TabContent>
          {" "}
          <BestPlayerWrap>
            {filteredTable?.map((item, index) => (
              <BestPlayersTable key={index}>
                <h2>{item.parameter_name}</h2>
                {item?.leaders.length > 0 ? (
                  <>
                    {item?.leaders?.slice(0, 5)?.map((leader, idx) => (
                      <BestPlayerContent key={idx}>
                        <BestPlayerWrapper>
                          <BestPlayerName
                            href={`/tournaments/player/${leader.player.id}`}
                          >
                            {/* <img src={star} alt={star} /> */}
                            <img
                              src={leader.player.photo ?? emptyState}
                              alt={leader.player.photo ?? emptyState}
                              className="profileImage"
                            />
                            <h5>{leader.player.display_name}</h5>
                          </BestPlayerName>
                          <BestPlayerValue>
                            <h5
                              style={
                                isCanClickPlayerStat(leader, item)
                                  ? { cursor: "not-allowed" }
                                  : { cursor: "pointer" }
                              }
                              onClick={() =>
                                !isCanClickPlayerStat(leader, item) &&
                                handleClickPlayerParam(item, leader)
                              }
                            >
                              {leader.value}
                            </h5>
                          </BestPlayerValue>
                        </BestPlayerWrapper>
                      </BestPlayerContent>
                    ))}
                    {item?.leaders?.length > 5 && (
                      <>
                        {expandedIndices.includes(index)
                          ? item?.leaders?.slice(5)?.map((leader, idx) => (
                            <BestPlayerContent key={idx}>
                              <BestPlayerWrapper>
                                <BestPlayerName
                                  href={`/tournaments/player/${leader.player.id}`}
                                >
                                  {/* <img src={star} alt={star} /> */}
                                  <img
                                    src={leader.player.photo ?? emptyState}
                                    alt={leader.player.photo ?? emptyState}
                                    className="profileImage"
                                  />
                                  <h5>{leader.player.display_name}</h5>
                                </BestPlayerName>
                                <BestPlayerValue>
                                  <h5
                                    style={
                                      isCanClickPlayerStat(leader, item)
                                        ? { cursor: "not-allowed" }
                                        : { cursor: "pointer" }
                                    }
                                    onClick={() =>
                                      !isCanClickPlayerStat(leader, item) &&
                                      handleClickPlayerParam(item, leader)
                                    }
                                  >
                                    {leader.value}
                                  </h5>
                                </BestPlayerValue>
                              </BestPlayerWrapper>
                            </BestPlayerContent>
                          ))
                          : null}
                        <button onClick={() => toggleShowLeads(index)}>
                          {expandedIndices.includes(index)
                            ? "Show less"
                            : "Show more"}
                        </button>
                      </>
                    )}
                  </>
                ) : (
                  <h2>No data</h2>
                )}
              </BestPlayersTable>
            ))}
          </BestPlayerWrap>
        </TabContent>

        <TabContent>
          {" "}
          <BestPlayerWrap>
            {gkTable?.map((item, index) => (
              <BestPlayersTable key={index}>
                <h2>{item.parameter_name}</h2>
                {item?.leaders.length > 0 ? (
                  <>
                    {item?.leaders?.slice(0, 5)?.map((leader, idx) => (
                      <BestPlayerContent key={idx}>
                        <BestPlayerWrapper>
                          <BestPlayerName
                            href={`/tournaments/player/${leader.player.id}`}
                          >
                            {/* <img src={star} alt={star} /> */}
                            <img
                              src={leader.player.photo ?? emptyState}
                              alt={leader.player.photo ?? emptyState}
                              className="profileImage"
                            />
                            <h5>{leader.player.display_name}</h5>
                          </BestPlayerName>
                          <BestPlayerValue>
                            <h5
                              style={
                                isCanClickPlayerStat(leader, item)
                                  ? { cursor: "not-allowed" }
                                  : { cursor: "pointer" }
                              }
                              onClick={() =>
                                !isCanClickPlayerStat(leader, item) &&
                                handleClickPlayerParam(item, leader)
                              }
                            >
                              {leader.value}
                            </h5>
                          </BestPlayerValue>
                        </BestPlayerWrapper>
                      </BestPlayerContent>
                    ))}
                    {item?.leaders?.length > 5 && (
                      <>
                        {expandedIndices.includes(index)
                          ? item?.leaders?.slice(5)?.map((leader, idx) => (
                            <BestPlayerContent key={idx}>
                              <BestPlayerWrapper>
                                <BestPlayerName
                                  href={`/tournaments/player/${leader.player.id}`}
                                >
                                  {/* <img src={star} alt={star} /> */}
                                  <img
                                    src={leader.player.photo ?? emptyState}
                                    alt={leader.player.photo ?? emptyState}
                                    className="profileImage"
                                  />
                                  <h5>{leader.player.display_name}</h5>
                                </BestPlayerName>
                                <BestPlayerValue>
                                  <h5
                                    style={
                                      isCanClickPlayerStat(leader, item)
                                        ? { cursor: "not-allowed" }
                                        : { cursor: "pointer" }
                                    }
                                    onClick={() =>
                                      !isCanClickPlayerStat(leader, item) &&
                                      handleClickPlayerParam(item, leader)
                                    }
                                  >
                                    {leader.value}
                                  </h5>
                                </BestPlayerValue>
                              </BestPlayerWrapper>
                            </BestPlayerContent>
                          ))
                          : null}
                        <button onClick={() => toggleShowLeads(index)}>
                          {expandedIndices.includes(index)
                            ? "Show less"
                            : "Show more"}
                        </button>
                      </>
                    )}
                  </>
                ) : (
                  <h2>No data</h2>
                )}
              </BestPlayersTable>
            ))}
          </BestPlayerWrap>
        </TabContent>
      </TabsWrapper>

      {/* display notifications */}
      <NotificationList
        notifications={notifications}
        onClose={removeNotification}
      />
    </>
  );
};

export default BestPlayers;
