import React from "react";

// Style
import {
  TableWrap,
  TableContent,
  ProfileImage,
  LeftContent,
  ImagesWrap,
  RightContent,
  SeeMoreWrap,
} from "../../style/FavoriteTable";

// Images
import star from "../../images/ic_start.svg";
import emptyState from "../../images/contactLogo.svg";
import sort from "../../images/ic_sort.svg";
import arrowDark from "../../images/arrow_dark.svg";
import arrowLight from "../../images/arrow_white.svg";

// Components
import Empty from "../../components/empty/index";

// Language
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { $apiV1 } from "../../config/api";
import { AlfabetSort } from "../../style/Playlist";
import { PageButton, PaginationWrapper } from "../../style/Favorites";

const FavoritesTable = ({
  table,
  setFavList,
  sortOrder,
  onSortChange,
  page,
  totalPages,
  onPageChange,
}) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const handleRemoveFromFavorites = async (item) => {
    try {
      await $apiV1.delete(`/users/favorites/players/${item?.player_id}/`);
      window.location.reload();
    } catch (error) {
      console.error("Error fetching players:", error);
    }
  };

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      onPageChange(newPage);
    }
  };

  return (
    <>
      <AlfabetSort
        onClick={onSortChange}
        style={{ cursor: "pointer", marginBottom: "10px" }}
      >
        <h6>{t("playlist.sortAlphabet")}</h6>
        <img src={sort} alt="sort" />
      </AlfabetSort>
      {table?.length > 0 ? (
        <>
          <TableWrap>
            {table.map((item, index) => {
              return (
                <TableContent key={index}>
                  <LeftContent style={{ color: "#fff" }}>
                    <ImagesWrap>
                      <img
                        src={star}
                        alt={star}
                        style={{ cursor: "pointer" }}
                        onClick={() => handleRemoveFromFavorites(item)}
                      />
                      <ProfileImage
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          navigate(`/tournaments/player/${item.player_id}`)
                        }
                        onContextMenu={() =>
                          window.open(
                            `/tournaments/player/${item.player_id}`,
                            "_blank"
                          )
                        }
                      >
                        <img
                          src={
                            item?.player?.photos.length > 0
                              ? `https://platform.smrtstats.com:8888/${item?.player?.photos[0]?.image}`
                              : emptyState
                          }
                          alt={emptyState}
                          style={{
                            width: "40px",
                            height: "40px",
                            objectFit: "contain",
                          }}
                        />
                        <span>
                          <img src={item.flag} alt={item.flag} />
                        </span>
                      </ProfileImage>
                    </ImagesWrap>
                    <h3
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        navigate(`/tournaments/player/${item.player_id}`)
                      }
                      onContextMenu={() =>
                        window.open(
                          `/tournaments/player/${item.player_id}`,
                          "_blank"
                        )
                      }
                    >
                      {item?.player?.display_name}
                    </h3>
                  </LeftContent>
                  <RightContent>
                    <SeeMoreWrap
                      onClick={() =>
                        navigate(`/tournaments/player/${item.player_id}`)
                      }
                      onContextMenu={() =>
                        window.open(
                          `/tournaments/player/${item.player_id}`,
                          "_blank"
                        )
                      }
                    >
                      {t("favorites.button")}
                    </SeeMoreWrap>
                  </RightContent>
                </TableContent>
              );
            })}
          </TableWrap>
          <PaginationWrapper>
            <PageButton onClick={() => handlePageChange(page - 1)}>
              <img
                src={
                  localStorage.getItem("theme") === "light"
                    ? arrowLight
                    : arrowDark
                }
                alt={arrowDark}
                className="leftTransform"
              />
            </PageButton>
            <span>
              {page} of {totalPages.toLocaleString()}
            </span>
            <PageButton onClick={() => handlePageChange(page + 1)}>
              <img
                className="rightTransform"
                src={
                  localStorage.getItem("theme") === "light"
                    ? arrowLight
                    : arrowDark
                }
                alt={arrowDark}
              />
            </PageButton>
          </PaginationWrapper>
        </>
      ) : (
        <Empty
          title={t("favorites.emptyTitle")}
          text={t("favorites.emptyDesc")}
          button1={t("favorites.emptyButton1")}
          button2={t("favorites.emptyButton2")}
        />
      )}
    </>
  );
};

export default FavoritesTable;
