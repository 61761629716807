import styled from "styled-components";
import { breakpoints } from "./size";

export const TopStatisticsWrapper = styled.div`
  display: flex;
  align-items: center;

  @media only screen and (${breakpoints.mobile}) {
    // flex-wrap: wrap;
    flex-direction: row;
    flex-wrap: nowrap;
    overflow-x: auto;
    white-space: nowrap;
    gap: 20px;
  }
`;

export const TopStatCard = styled.div`
  padding: 0 30px 0 10px;
  border-left: 5px solid ${(props) => props.theme.blueish};

  h5 {
    opacity: 1;
    color: ${(props) => props.theme.green};
    font-family: "SpaceGrotesk";
    font-size: 16px;
    font-weight: 500;
    font-style: Medium;
    letter-spacing: 0px;
    text-align: left;
    line-height: 24px;
  }

  &:nth-child(1) {
    width: 40%;

    @media only screen and (${breakpoints.mobile}) {
      width: 100%;
    }
  }

  &:nth-child(2) {
    width: 20%;

    @media only screen and (${breakpoints.mobile}) {
      width: 100%;
    }
  }

  &:nth-child(3) {
    width: 20%;

    @media only screen and (${breakpoints.mobile}) {
      width: 100%;
    }
  }

  &:nth-child(4) {
    width: 20%;

    @media only screen and (${breakpoints.mobile}) {
      width: 100%;
    }
  }
`;

export const NumbersStat = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media only screen and (${breakpoints.mobile}) {
    margin-bottom: 10px;
    gap: 20px;
  }

  h3 {
    opacity: 1;
    color: ${(props) => props.theme.blueish};
    font-family: "SpaceGrotesk";
    font-size: 28px;
    font-weight: 700;
    font-style: Bold;
    letter-spacing: 1px;
    text-align: left;
    line-height: 40px;
  }
`;

export const ClubsPlayersWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin: 80px 0;
  gap: 30px;

  @media only screen and (${breakpoints.mobile}) {
    flex-direction: column;
    margin: 40px 0;
  }
`;

export const ClubsWrapper = styled.div`
  width: 40%;
  // height: 500px;
  // overflow: scroll;

  @media only screen and (${breakpoints.mobile}) {
    width: 100%;
  }
`;

export const SearchInputWrapper = styled.div`
  margin-bottom: 20px;

  input {
    border-radius: 1px;
    opacity: 1;
    width: 100%;
    padding: 10px;
    background: transparent;
    box-shadow: none;
    border: none;
    outline: none;
    color: ${(props) => props.theme.blueish} !important;

    opacity: 1;
    color: rgba(207, 218, 230, 1);
    font-family: "SatoshiMedium";
    font-size: 16px;
    font-weight: 400;
    font-style: Regular;
    letter-spacing: 0px;
    text-align: left;
    line-height: 24px;

    &:placeholder {
      color: #2c3230;
    }
  }

  .searchable-dropdown {
    position: relative;
    border: 1px solid ${(props) => props.theme.blueish3};
  }

  .dropdown-list {
    position: absolute;
    border-radius: 2px;
    opacity: 1;
    background-color: ${(props) => props.theme.black};
    width: 100%;
    max-height: 150px;
    overflow-y: auto;
    z-index: 1000;
    list-style-type: none;
    padding: 0;
    margin: 0;
  }

  .dropdown-list li {
    padding: 10px;
    cursor: pointer;
  }

  .dropdown-list li:hover {
    background: rgb(63 63 63 / 40%);
  }

  h5 {
    opacity: 1;
    color: rgba(115, 128, 137, 1);
    font-family: "SatoshiMedium";
    font-size: 12px;
    font-weight: 500;
    font-style: Medium;
    letter-spacing: 0.5px;
    text-align: left;
    line-height: 18px;
    margin-bottom: 5px;
    margin-left: 20px;
  }
`;

export const SearhWrap = styled.div`
  display: flex;
  align-items: center;
  padding-left: 20px;
  position: relative;

  .closeDropdown {
    position: absolute;
    right: 10px;
    cursor: pointer;
  }
`;

export const SearchItem = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  h5 {
    opacity: 1;
    color: ${(props) => props.theme.white};
    font-family: "SatoshiRegular";
    font-size: 16px;
    font-weight: 400;
    font-style: Regular;
    letter-spacing: 0px;
    text-align: left;
    line-height: 24px;
    padding: 0;
    margin: 0;
  }

  img {
    width: 20px;
    height: 20px;
    border-radius: 50%;
  }
`;

export const EmptySearch = styled.div`
  padding: 10px;
  h5 {
    opacity: 1;
    color: ${(props) => props.theme.white};
    font-family: "SatoshiRegular";
    font-size: 16px;
    font-weight: 400;
    font-style: Regular;
    letter-spacing: 0px;
    text-align: left;
    line-height: 24px;
    padding: 0;
    margin: 0;
  }
`;

export const ClubCard = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid ${(props) => props.theme.lines};
  opacity: 1;
  justify-content: space-between;

  &:nth-child(even) {
    background: ${(props) => props.theme.lines};
  }

  &.selectedClub {
    background: ${(props) => props.theme.green};
  }

  &.selectedClub a {
    color: ${(props) => props.theme.black};
  }

  .seeClubs {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background: ${(props) => props.theme.green2};
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    img {
      width: 6px;
    }
  }
`;

export const ClubContent = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  h3 {
    opacity: 1;
    color: ${(props) => props.theme.green};
    font-family: "SatoshiMedium";
    font-size: 16px;
    font-weight: 500;
    font-style: Medium;
    letter-spacing: 0px;
    text-align: left;
    line-height: 24px;
    max-width: 200px;
    overflow: hidden;
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  h5 {
    opacity: 1;
    color: rgba(115, 128, 137, 1);
    font-family: "SatoshiMedium";
    font-size: 12px;
    font-weight: 500;
    font-style: Medium;
    letter-spacing: 0.5px;
    text-align: left;
    line-height: 18px;
  }

  .flag {
    width: 40px;
    hegiht: 40px;
  }
`;

export const Location = styled.div`
  a {
    opacity: 1;
    color: ${(props) => props.theme.green};
    font-family: "SatoshiMedium";
    font-size: 16px;
    font-weight: 500;
    font-style: Medium;
    letter-spacing: 0px;
    text-align: left;
    line-height: 24px;
    max-width: 200px;
    overflow: hidden;
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
`;

export const TeamsWrapper = styled.div`
  width: 100%;
  height: 500px;
  overflow: scroll;
  border-radius: 5px;
  border: 1px solid ${(props) => props.theme.borderColor};
  box-shadow: 3px 2px 30px rgba(68, 68, 68, 0.12);
  opacity: 1;

  @media only screen and (${breakpoints.mobile}) {
    width: 100%;
  }
`;

export const TeamsCard = styled.div`
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:nth-child(even) {
    background: ${(props) => props.theme.lines};
  }
`;
export const TeamContent = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  width: 40%;

  .logo {
    width: 30px;
  }

  h3 {
    opacity: 1;
    color: ${(props) => props.theme.white};
    font-family: "SatoshiMedium";
    font-size: 16px;
    font-weight: 500;
    font-style: Medium;
    letter-spacing: 0px;
    text-align: left;
    line-height: 24px;
  }
`;

export const PlayersCount = styled.div`
  width: 30%;

  h3 {
    opacity: 1;
    color: ${(props) => props.theme.white};
    font-family: "SatoshiMedium";
    font-size: 16px;
    font-weight: 500;
    font-style: Medium;
    letter-spacing: 0.5px;
    text-align: left;
    line-height: 24px;
    text-align: center;
  }
`;
export const SeeMoreTeam = styled.a`
  width: 30%;
  cursor: pointer;
  text-decoration: none;

  h3 {
    opacity: 1;
    color: ${(props) => props.theme.white};
    font-family: "SatoshiMedium";
    font-size: 16px;
    font-weight: 500;
    font-style: Medium;
    letter-spacing: 0.5px;
    text-align: left;
    line-height: 24px;
    text-align: right;
  }
`;

export const TeamsWrapp = styled.div`
  width: 55%;

  @media only screen and (${breakpoints.mobile}) {
    width: 100%;
  }
`;

export const TopTable = styled.div`
  position: relative;

  .seeTournament {
    position: absolute;
    right: 0;
    top: -30px;
    display: flex;
    align-items: center;
    justify-content: center;

    @media only screen and (${breakpoints.mobile}){
      position: relative;
      top: 0;
      right: 0;
      margin: 20px 0;
    }
  }
`;

export const TableNamesTop = styled.div`
  display: flex;
  color: #fff;
  align-items: center;
  justify-content: space-between;
  padding-left: 20px;
  margin-bottom: 5px;

  h5 {
    opacity: 1;
    color: rgba(115, 128, 137, 1);
    font-family: "SatoshiMedium";
    font-size: 12px;
    font-weight: 500;
    font-style: Medium;
    letter-spacing: 0.5px;
    text-align: left;
    line-height: 18px;

    &:nth-child(1) {
      width: 22%;
    }
  }

  button {
    border-radius: 1px;
    border: none;
    opacity: 1;
    background-color: ${(props) => props.theme.green};
    color: ${(props) => props.theme.black};
    opacity: 1;
    font-family: "SpaceGrotesk";
    font-size: 16px;
    font-weight: 700;
    font-style: Bold;
    letter-spacing: 0.5px;
    text-align: center;
    line-height: 24px;
    text-transform: uppercase;
    width: 92px;
    height: 40px;
    cursor: pointer;
  }
`;

export const EmptyState = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
  height: 70%;
  gap: 20px;

  h3 {
    opacity: 1;
    color: ${(props) => props.theme.white};
    font-family: "SatoshiRegular";
    font-size: 20px;
    font-weight: 400;
    font-style: Regular;
    letter-spacing: 0px;
    line-height: 28px;
  }
`;
