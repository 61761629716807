import React, { useEffect, useMemo, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import {
    TopWrapperVideo,
    TopWrapperVideoContent,
    VideoContent,
    VideoPlayerWrapper,
    VideoWrapper
} from '../../style/VideoStyle';
import { VideoPlayer } from '../tournametns/player/ui/VideoPlayer';
import { FormWrapPopup, MainWrapper, TopWrapper } from '../../style/DasboardMain';
import Navigation from '../../components/Navigation/Navigation';
import IntroText from "../../components/introText/index";
import Button from '../../components/button';
import TextInput from '../../components/inputs/TextInput';
import Modal from "../../components/modal/index";
import { Dropdown, DropdownButton, DropdownContent, DropdownItem } from '../tournametns/player/ShotsPlayer';

import arrowIconUp from "../../images/up.svg";
import arrowIconDown from "../../images/down.svg";
import { $apiV1, VIDEO_SERVICE_URL } from '../../config/api';
import { VideoListPanel } from '../../components/playlist/VideoListPanel';


const Index = () => {
    const { id } = useParams();
    const [searchParams] = useSearchParams();

    const [playlist, setPlaylist] = useState(null);
    const [videoHref, setVideoHref] = useState('');
    const [videoSecond] = useState(0);
    const [offsets, setOffsets] = useState({ start: 10, end: 10 });
    const [loadingVideo, setLoadingVideo] = useState(false);

    const [openAddVideo, setOpenAddVide] = useState(false);
    const [folderName, setFolderName] = useState("");

    const [userPlaylists, setUserPlaylists] = useState([]);
    const [selectedPlaylist, setSelectedPlaylist] = useState(null);
    const [showDropdown, setShowDropdown] = useState(false);
    const [loadingAddPlaylist, setLoadingAddPlaylist] = useState(false);

    const [record, setRecord] = useState(null);
    const [recordTime, setRecordTime] = useState(null);

    const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
    const [disabledNext, setDisabledNext] = useState(true);
    const [disabledPrev, setDisabledPrev] = useState(true);

    useEffect(() => {
        const getUserPlaylists = async () => {
            try {
                const res = await $apiV1.get('/users/playlists/');
                setUserPlaylists(res.data);
            } catch (e) {
                console.log('get user playlists err', e.message);
            }
        }

        getUserPlaylists();
    }, [])

    useEffect(() => {
        const video_id = searchParams.get('video');

        const getPlaylist = async (id, video_id) => {
            try {
                const response = await $apiV1.get(`/users/playlists/${id}/`);
                setPlaylist(response.data);
                if (video_id) {
                    const existVideoIndex = response.data?.videos?.findIndex(item => item.id === video_id);
                    if (existVideoIndex >= 0) {
                        handlePlayVideo(response.data?.videos?.[existVideoIndex], existVideoIndex);
                    }
                }
            } catch (e) {
                console.log('get playlist error', e.message);
                alert(`Get playlist error: ${e.message}`)
            }
        }
        id && getPlaylist(+id, Number(video_id));
    }, [searchParams, id])

    useEffect(() => {
        setDisabledPrev(currentVideoIndex === 0);
        setDisabledNext(currentVideoIndex >= playlist?.videos?.length - 1);
        // handlePlayVideo(videoSource);
    }, [currentVideoIndex, playlist]);

    const handleSelectChange = (value) => {
        setSelectedPlaylist(value);
    };

    const handleEmailChange = (newValue) => {
        setFolderName(newValue);
    };

    const addRecordToPlaylist = () => {
        setOpenAddVide(true);
    }

    const handlePrevVideo = () => {
        if (currentVideoIndex > 0) {
            handlePlayVideo(playlist?.videos?.[currentVideoIndex - 1]);
        }
    };

    const handleNextVideo = () => {
        if (currentVideoIndex < playlist?.videos?.length - 1) {
            handlePlayVideo(playlist?.videos?.[currentVideoIndex + 1]);
        }
    };

    const addNewFolder = async () => {
        try {
            setLoadingAddPlaylist(true);
            let playlist;

            if (!selectedPlaylist) {
                // First API request to create a new folder
                const createFolderResponse = await $apiV1.post(`/users/playlists/`,
                    { title: folderName },
                );
                playlist = createFolderResponse.data;
                setUserPlaylists(prev => [...prev, playlist])
            } else {
                playlist = selectedPlaylist;
            }

            setOpenAddVide(!openAddVideo);

            if (record?.id && recordTime?.startTime && recordTime?.endTime) {
                const params = {
                    end_second: +(+recordTime.endTime).toFixed(0),
                    start_second: +(+recordTime.startTime).toFixed(0),
                    event_id: record.id,
                    title: `Record from ${recordTime.startTime.toFixed(0)} to ${recordTime.endTime.toFixed(0)}`
                }
                // await getCutVideo(params);
                await $apiV1.post(`/users/playlists/${playlist.id}/add/`,
                    [params],
                );
                return
            }
        } catch (error) {
            console.error("Error processing request:", error);
        } finally {
            setLoadingAddPlaylist(false);
        }
    };

    const handleChangeOffSets = (start, end) => {
        setOffsets({ start, end })
    }

    const handlePlayVideo = async (video, idx) => {
        try {
            const video_idx = playlist?.videos?.findIndex(item => item.id === video.id);
            setCurrentVideoIndex(video_idx || idx || 0);

            if (video?.details?.video) {
                setLoadingVideo(true);
                setRecord(video);

                const url = `${VIDEO_SERVICE_URL}/cut_video/`;
                const response = await fetch(url, {
                    body: JSON.stringify({
                        path: decodeURI(video?.details?.video),
                        start: video?.start_second,
                        end: video?.end_second
                    }),
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    method: 'POST',
                });
                const json = await response.json();

                if (json.status === 'ok') {
                    setVideoHref(`${VIDEO_SERVICE_URL}/video/${json.out_file}`);
                    // const path = searchParams.size ? `?${decodeURI(searchParams.toString())}` : '';
                    // $apiV1.post('/users/activity/', {
                    //     entity_id: id,
                    //     link_from: `https://platform.smrtstats.com${location.pathname}${path}`,
                    //     link_to: `${VIDEO_SERVICE_URL}/video/${json.out_file}`,
                    //     type: 3,
                    // })
                } else if (json.status === 'error') {
                    alert(json.input_path || json.start || json.out_file);
                } else {
                    alert('Get video error');
                }
            }
        } catch (e) {
            alert(`Get video error, ${e.message}`);
            console.log('get cut video err', e.message);
        } finally {
            setLoadingVideo(false);
        }
    };

    const modal = (
        <Modal isOpen={openAddVideo} setIsOpen={setOpenAddVide} closeIc>
            <h1>
                Add record to Playlist
            </h1>
            <FormWrapPopup style={{ marginTop: "20px" }}>
                <Dropdown>
                    <DropdownButton
                        onClick={() => setShowDropdown(!showDropdown)}
                    >
                        {!selectedPlaylist
                            ? "Select playlist"
                            : `${selectedPlaylist?.title}`}
                        <img src={showDropdown ? arrowIconUp : arrowIconDown} />
                    </DropdownButton>
                    <DropdownContent show={showDropdown}>
                        {userPlaylists.map((item) => (
                            <DropdownItem
                                key={item.id}
                                selected={item.id === selectedPlaylist?.id}
                                onClick={() => handleSelectChange(item)}
                            >
                                {item.title}
                            </DropdownItem>
                        ))}
                    </DropdownContent>
                </Dropdown>
                <TextInput
                    label="Create New Playlist"
                    type={"text"}
                    value={folderName}
                    placeholder="Write Name"
                    onChange={handleEmailChange}
                />
            </FormWrapPopup>
            <Button
                style={{ zIndex: 1 }}
                title="Add to playlist"
                size={"full"}
                onClick={() => addNewFolder()}
                disabled={loadingAddPlaylist}
            />
        </Modal>
    );

    return (
        <MainWrapper>
            <Navigation />

            <VideoWrapper
                className={
                    localStorage.getItem("navigationOpen") === "true"
                        ? ""
                        : "fullWidthPage"
                }
            >
                <TopWrapper>
                    <IntroText title={`Playlist ${playlist?.title}`} />
                </TopWrapper>
                <VideoPlayerWrapper>
                    <VideoListPanel
                        handlePlayVideo={handlePlayVideo}
                        currentVideoIndex={currentVideoIndex}
                        playlist={playlist}
                    />

                    <VideoContent>
                        <VideoPlayer
                            video={videoHref}
                            toSecond={videoSecond}
                            playNextVideo={handleNextVideo}
                            playPrevVideo={handlePrevVideo}
                            videos={playlist?.videos || []}
                            offsets={offsets}
                            changeOffSets={handleChangeOffSets}
                            loading={loadingVideo}
                            record={record}
                            setRecord={setRecord}
                            recordTime={recordTime}
                            setRecordTime={setRecordTime}
                            addRecordToPlaylist={addRecordToPlaylist}
                            disabledNext={disabledNext}
                            disabledPrev={disabledPrev}
                        />
                    </VideoContent>
                </VideoPlayerWrapper>
            </VideoWrapper>
            {modal}
        </MainWrapper>
    );
}

export default Index;