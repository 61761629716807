import React from "react";

// Style
import styled from "styled-components";

// Images
import lock from "../../images/ic_lock.svg";
import { BottomText } from "./PasswordInput";

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 7px;
  position: relative;

  .dropdownIcon{
    position: absolute;
    right: 0px;
    cursor: pointer;
  }

  label, span {
    opacity: 1;
    color: ${(props) => props.theme.blueish2};
    font-family: "SatoshiRegular";
    font-size: 12px;
    font-weight: 400;
    font-style: Regular;
    letter-spacing: 0px;
    text-align: left;
    line-height: 18px;
    padding-left: 5px;
  }

  span {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  input {
    background: transparent;
    border: none;
    border-bottom: 1px solid ${(props) =>
      props.error
        ? (props) => props.theme.red
        : (props) => props.theme.blueish2};
    outline: none;
    opacity: 1;
    color: ${(props) => props.theme.blueish};
    font-family: "SatoshiRegular";
    font-size: 16px;
    font-weight: 400;
    font-style: Regular;
    letter-spacing: 0px;
    text-align: left;
    line-height: 24px;
    padding-left: 5px;
    padding-bottom: 4px;

    &:placeholder {
      opacity: 1;
      color: ${(props) => props.theme.blueish2};
      font-family: "SatoshiRegular";
      font-size: 16px;
      font-weight: 400;
      font-style: Regular;
      letter-spacing: 0px;
      text-align: left;
      line-height: 24px;
    }

    &:disabled {
      color: ${(props) => props.theme.blueish2}; !important;
    }


  }
`;

const TextInput = ({
  label,
  type,
  placeholder,
  value,
  onChange,
  bottomText,
  disabled,
  error,
  readOnly,
}) => {
  const handleChange = (event) => {
    onChange(event.target.value);
  };

  return (
    <>
      <InputWrapper error={error}>
        <label>{label}</label>
        <input
          type={type}
          placeholder={placeholder}
          value={value}
          onChange={handleChange}
          disabled={disabled}
          className={error ? "errorStyle" : ""}
          readOnly={readOnly}
        />
        {disabled && (
          <div
            style={{
              position: "absolute",
              right: "10px",
              bottom: "5px",
            }}
          >
            <img src={lock} alt={lock} />
          </div>
        )}
      </InputWrapper>
      {!error && (
        <BottomText className="bottom-text" error={error}>
          {bottomText}
        </BottomText>
      )}
      {error && (
        <BottomText className="bottom-text" error={error}>
          {error}
        </BottomText>
      )}
    </>
  );
};

export default TextInput;
