import React, { useEffect, useMemo, useRef, useState } from 'react'
import backgroundImage from "../../images/ic_filed.png";
import { fieldHeight, fieldPadding, fieldWidth } from './constants/fieldVars';
import { API_URL_V1 } from '../../config/api';
import { availableActionPositionIds, getLeftZoneByShortName, getRightZoneByShortName } from './helpers/zones';
import { convertCoordinatesWithPadding } from './helpers/convertCoordinatesWithPadding';
import { ChangeFormationTime } from './ui/ChangeFormationTime';
import { Oval } from 'react-loader-spinner';
import styled from 'styled-components';

import close from "../../images/ic_close.svg";
import { PlayerDetailsModal } from './ui/PlayerDetailsModal';

const FormationOverlay = styled.div`
    width: 100%;
    min-height: 100vh;
    background: ${(props) => props.theme.bgOverlay};
    background-size: cover;
    background-repeat: no-repeat;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9;
    display: flex;
    justify-content: center;
    align-items: center;
}
`

const FormationModal = styled.div`
    border: 1px solid ${(props) => props.theme.borderModal};
    border-radius: 3px;
    padding: 30px 30px 60px 30px;
    width: 878px;
    min-height: 400px;
    z-index: 999;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: ${(props) => props.theme.black};
    
    h2 {
        font-size: 36px;
        text-align: center;
        margin-top: 10px;
        margin-bottom: 20px;
        color: ${(props) => props.theme.white};
    font-family: "SpaceGrotesk";

    }
`

const FormationContainer = styled.div`
`

export const CloseIcon = styled.img`
  position: absolute;
  right: 30px;
  top: 30px;
  cursor: pointer;
`;

export const FormationMatchTitle = styled.div`
    text-align: center;
    margin-bottom: 20px;

    p {
        font-size: 16px;
        font-weight: 600;
        color: ${(props) => props.theme.white};
        font-family: "SpaceGrotesk";
    }

    span {
        font-size: 12px;
        font-weight: 500;
        color: ${(props) => props.theme.blueish2};
        font-family: "SpaceGrotesk";

        b {
            font-weight: 700;
            color: ${(props) => props.theme.blueish2};
            font-family: "SpaceGrotesk";
        }
    }
`;

export const FormationScoreContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 25px;
    position: relative;
`

export const FormationTeamTitle = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;

    b {
        font-size: 28px;
        color: ${(props) => props.theme.white};
        font-family: "SpaceGrotesk";
        font-weight: 500;
        max-width: 215px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    span {
        width: 30px;
        height: 30px;
        background: #000000;
        border: 1px solid ${(props) => props.theme.lines};
        border-radius: 2px;
        padding: 5px;
        font-family: "SpaceGrotesk";

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }

`

export const FormationScore = styled.div`
    color: ${(props) => props.theme.white};
    font-size: 36px;
    font-weight: 700;
    font-family: "SpaceGrotesk";
    position: absolute;
    display: flex;
    justify-content: center;
    margin: 0 auto;
    width: 100%;
`

export const ChangeFormationTimeContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 28px;
    font-family: "SpaceGrotesk";
    position: relative;
    user-select: none;

    p, span {
        color: ${(props) => props.theme.white};
        font-size: 28px;
        font-weight: 500;
        font-family: "SpaceGrotesk";
    }

    div {
        display: flex;
        align-items: center;
        gap: 15px; 
        position: absolute;
        display: flex;
        justify-content: center;
        width: 100%;

        svg {
            fill: ${(props) => props.theme.white};
        }
    }
`

const availableFormationMarkers = {
    "3-5-2": 106,
    "3-5-2*": 105,
    "3-3-3-1": 103,
    "3-4-3": 90,
    "4-1-4-1": 111,
    "4-2-3-1": 82,
    "4-3-3": 3,
    "4-4-2 classic": 96,
    "4-4-2 diamond": 16,
    "3-4-1-2": 211,
    "4-3-2-1": 212,
    "4-4-1-1": 210,
    "4-5-1": 208,
    "5-3-2": 207,
    "5-4-1": 209,
    "GK": 4,
    "LD": 8,
    "LDM": 95,
    "LM": 19,
    "LAM": 13,
    "LF": 198,
    "LCD": 5,
    "LCDM": 84,
    "LCM": 9,
    "LCAM": 92,
    "LCF": 20,
    "CD": 93,
    "CDM": 10,
    "CM": 112,
    "CAM": 17,
    "CF": 12,
    "RCD": 6,
    "RCDM": 83,
    "RCM": 11,
    "RCAM": 94,
    "RCF": 21,
    "RD": 7,
    "RDM": 91,
    "RM": 18,
    "RAM": 14,
    "RF": 199,
}

const availableActionMarkerIdx = [65, 100, 86, 55, 206, 77] // Goal, Own Goal, RC, YC, RC-YC, Substitution

export const Formation = (props) => {
    const { match, handleClose, open } = props;

    const [matchInfo, setMatchInfo] = useState(null);

    const [loadingMatchMarkers, setLoadingMatchMarkers] = useState(true);
    const [matchMarkers, setMatchMarkers] = useState([]);

    const [loadingFormationMarkers, setLoadingFormationMarkers] = useState(true);
    const [formationMarkers, setFormationMarkers] = useState([]);

    const [homeTactic, setHomeTactic] = useState('3-5-2');
    const [awayTactic, setAwayTactic] = useState('3-5-2');

    const [leftSidePlayers, setLefSidePlayers] = useState([]);
    const [rightSidePlayers, setRightidePlayers] = useState([]);

    const [changeSide, setChangeSide] = useState(false);

    const [currentActions, setCurrentActions] = useState([]);
    const currentActionsRef = useRef([]);
    const [currentTime, setCurrentTime] = useState(0);
    const [disableNextButton, setDisableNextButton] = useState(true);
    const [disablePrevButton, setDisablePrevButton] = useState(true);

    const [openPlayerDetailsModal, setOpenPlayerDetailsModal] = useState(false);
    const [selectedPlayerId, setSelectedPlayerId] = useState(null);
    const [savedPlayersData, setSavedPlayersData] = useState([]);
    const [savedPlayer, setSavedPlayer] = useState(null);

    const actionMarkers = useMemo(() => {
        if (matchMarkers.length > 0) {
            const filteredMarkers = matchMarkers.filter((marker) => availableActionMarkerIdx.includes(marker.action.id));
            setDisableNextButton(false);
            return filteredMarkers;
        }
        return [];
    }, [matchMarkers])

    const secondHalfSeconds = useMemo(() => {
        if (matchMarkers.length > 0) {
            const secondHalfMarker = matchMarkers.find((marker) => marker.action.id === 75);
            if (secondHalfMarker) {
                return +secondHalfMarker.second;
            }
        }
        return 3000
    }, [matchMarkers])

    const getPositionsFromMarkers = (actions, side, color = '#000000', color_number = '#ffffff') => {
        try {
            if (actions.length > 0) {
                return actions?.filter(item => availableActionPositionIds.includes(item?.action?.id)).map((item, idx) => {
                    return {
                        id: idx + 1,
                        zone: side === 'left'
                            ? getLeftZoneByShortName(item?.action?.title)
                            : getRightZoneByShortName(item?.action?.title),
                        player: item?.creator,
                        color,
                        color_number
                    }
                })
            }
        } catch (e) {
            console.log('Get positions err:', e.message);
        }
    }

    const handlePlayersCircles = (markers, match, changeSide) => {
        try {
            const homeTacticKey = markers.find(item => !availableActionPositionIds.includes(item?.action?.id) && item.creator_team === match.home_team.id);
            const awayTacticKey = markers.find(item => !availableActionPositionIds.includes(item?.action?.id) && item.creator_team === match.away_team.id);
            homeTacticKey?.action?.title && setHomeTactic(homeTacticKey.action.title);
            awayTacticKey?.action?.title && setAwayTactic(awayTacticKey.action.title);

            const homeTactics = markers.filter(item => item.creator_team === match.home_team.id);
            const awayTactics = markers.filter(item => item.creator_team === match.away_team.id);
            const homePlacement = getPositionsFromMarkers(
                homeTactics,
                changeSide ? 'right' : 'left',
                match?.home_team?.color || '#000000',
                match?.home_team?.color_number || '#ffffff'
            );
            const awayPlacement = getPositionsFromMarkers(
                awayTactics,
                changeSide ? 'left' : 'right',
                match?.away_team?.color || '#ffffff',
                match?.away_team?.color_number || '#000000'
            );
            setLefSidePlayers(homePlacement);
            setRightidePlayers(awayPlacement);
        } catch (e) {
            console.log('set players circles err', e.message);
        }
    }

    useEffect(() => {
        currentActionsRef.current = currentActions;
    }, [currentActions])

    useEffect(() => {
        // get markers for drawing main events(YC,RC,Goals etc.)
        const getMatchMarkers = async (id, needFindFormationMarkers) => {
            try {
                setLoadingMatchMarkers(true);
                const response = await fetch(`${API_URL_V1}/registrator/match_markers_fast/${id}`);
                const json = await response.json();
                if (response.ok) {
                    setMatchMarkers(json.markers);
                    if (needFindFormationMarkers) {
                        const availableActionsIdsForFormation = Object.values(availableFormationMarkers);
                        const markersZeroSecond = json.markers.filter(item => Number(item.second) === 0);
                        const filteredFormationMarkers = markersZeroSecond.filter(item => availableActionsIdsForFormation.includes(item.action.id));
                        setFormationMarkers(filteredFormationMarkers);
                    }
                }
            } catch (e) {
                console.log('Get match markers err:', e.message);
            } finally {
                setLoadingMatchMarkers(false);
            }
        }

        // get markers for drawing players positions
        const getFormationMarkers = async (id) => {
            try {
                setLoadingFormationMarkers(true);
                const response = await fetch(`${API_URL_V1}/registrator/placement/${id}`);
                const json = await response.json();
                if (response.ok) {
                    setFormationMarkers(json.markers);
                    setMatchInfo(json.match);
                    return Promise.resolve(json);
                }
                return Promise.reject(json);
            } catch (e) {
                console.log('Get formation markers err:', e.message);
                return Promise.reject(e);
            } finally {
                setLoadingFormationMarkers(false);
            }
        }

        if (match?.id) {
            getFormationMarkers(match.id).then(res => {
                let needFindFormationOnCommonMarkersList = false;
                if (res?.markers?.length === 0) {
                    needFindFormationOnCommonMarkersList = true;
                }
                getMatchMarkers(match.id, needFindFormationOnCommonMarkersList)
            })
        }
        // match?.id && getFormationMarkers(match.id);
        // match?.id && getMatchMarkers(match?.id);

        return () => {
            setMatchMarkers([]);
            setFormationMarkers([]);
            setCurrentActions([]);
            setCurrentTime(0);
            setChangeSide(false);
            setLefSidePlayers([]);
            setRightidePlayers([]);
            setHomeTactic('3-5-2');
            setAwayTactic('3-5-2');
            setLoadingMatchMarkers(true);
            setLoadingFormationMarkers(true);
            setDisableNextButton(true);
            setDisablePrevButton(true);
            setMatchInfo(null);
            setOpenPlayerDetailsModal(false);
            setSavedPlayer(null)
            setSavedPlayersData([]);
            setSelectedPlayerId(null);
            currentActionsRef.current = [];
        }
    }, [match]);

    useEffect(() => {
        handlePlayersCircles(formationMarkers, matchInfo, changeSide);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [changeSide, matchInfo, formationMarkers])

    const handleGoal = (marker, pos) => {
        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="22"
                height="22"
                id={marker.id}
                x={pos?.x + 5}
                y={pos?.y + 5}
                viewBox="0 0 22 22"
                className={`goal-${marker?.creator?.player_id}`}
            >
                <defs>
                    <filter
                        id="filter-1"
                        width="250.8%"
                        height="250.8%"
                        x="-66.2%"
                        y="-66.1%"
                        filterUnits="objectBoundingBox"
                    >
                        <feOffset
                            dx="1"
                            dy="1"
                            in="SourceAlpha"
                            result="shadowOffsetOuter1"
                        ></feOffset>
                        <feGaussianBlur
                            in="shadowOffsetOuter1"
                            result="shadowBlurOuter1"
                            stdDeviation="2.5"
                        ></feGaussianBlur>
                        <feColorMatrix
                            in="shadowBlurOuter1"
                            result="shadowMatrixOuter1"
                            values="0 0 0 0 0.0156862745 0 0 0 0 0.031372549 0 0 0 0 0.00784313725 0 0 0 0.2 0"
                        ></feColorMatrix>
                        <feMerge>
                            <feMergeNode in="shadowMatrixOuter1"></feMergeNode>
                            <feMergeNode in="SourceGraphic"></feMergeNode>
                        </feMerge>
                    </filter>
                </defs>
                <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
                    <g
                        fill="#31CA29"
                        fillRule="nonzero"
                        stroke="#FFF"
                        strokeWidth="0.27"
                        transform="translate(-240 -477)"
                    >
                        <g transform="translate(20 340)">
                            <g filter="url(#filter-1)" transform="translate(224.345 141.867)">
                                <path d="M5.393 0A5.394 5.394 0 000 5.394 5.394 5.394 0 105.393 0zM9.09 2.34a7.02 7.02 0 00-.798-.624 3.18 3.18 0 00-.407-.42c.457.279.864.632 1.205 1.044h0zm-1.922.189c.457.568.503 1.294.534 1.775l.001.012c-.279.282-1.283 1.171-1.47 1.336-.593-.349-1.74-.59-1.95-.632-.147-.473-.377-1.285-.43-1.87l-.001-.02c.13-.184.631-.83 1.465-1.205.213.005 1.281.164 1.851.604h0zm-5.243-.177c.038-.068.185-.282.765-.919.369-.252.77-.45 1.192-.591h.148c.425 0 .816.02.972.03l.187.905a3.933 3.933 0 00-1.486 1.238 1.03 1.03 0 00-.385-.058c-.457 0-1.074.15-1.292.207-.131-.334-.107-.728-.1-.812h0zM.611 5.736a4.77 4.77 0 01.81-3.025c.142-.11.242-.178.31-.222-.003.19.016.506.137.786-.331.67-.52 1.207-.562 1.596a.933.933 0 00.015.345c-.262.166-.514.45-.638.602a1.189 1.189 0 00-.072-.082h0zm1.804 3.187l-.39-.117A4.78 4.78 0 011 7.317c-.276-.905-.246-1.235-.235-1.3.061-.079.352-.445.645-.634.148.22.602.837 1.181 1.052-.012.487.138.97.269 1.296.104.258.21.456.265.553-.181.172-.606.548-.71.64zm.905-.68c-.055-.09-.569-.97-.54-1.821.085-.08.353-.329.64-.58.558-.49.754-.61.82-.638.193.038 1.45.303 1.976.66.13.345.397 1.61.438 1.807-.19.222-.776.868-1.397 1.2-.751-.049-1.238-.217-1.515-.35a1.545 1.545 0 01-.422-.278zm2.073 1.947c-.064 0-.129-.002-.193-.004a1.87 1.87 0 00.39-.142c.05.018.1.037.152.058l.16.06a4.84 4.84 0 01-.51.028zm3.281-1.678l-.01.06a1.32 1.32 0 01-.27.562c-.6.482-1.313.815-2.065.964a3.843 3.843 0 01-.519-.172l-.166-.063a1.759 1.759 0 01-.253-.85c.645-.361 1.237-1.02 1.417-1.232.237.034.46.051.665.051.583 0 .897-.14 1.035-.225.088.179.193.48.197.898l-.031.007zm1.319-3.548c.054.108.128.29.192.575a4.775 4.775 0 01-1.023 2.819c-.098.04-.193.071-.27.095a2.226 2.226 0 00-.231-.961c.556-.482.634-1.714.644-1.97.281-.146.537-.394.688-.558h0zm-.328-1.748c.034.066.067.134.098.202.04.337.13 1.111.13 1.359l-.002.018c-.13.148-.382.404-.656.55-.37-.488-1.07-.901-1.345-1.052-.03-.457-.078-1.208-.521-1.812l.835-.597c.156.104.88.608 1.461 1.332h0z"></path>
                            </g>
                        </g>
                    </g>
                </g>
            </svg>
        )
    }

    const handleSubstitution = (marker, pos, prevSubst) => {
        const pitchPlayerRecipient = document.getElementById(`${marker?.recipient?.player_id}`);
        const pitchPlayerCreator = document.getElementById(`${marker?.creator?.player_id}`);
        const allYC = document.querySelectorAll(`.yellow-card-${marker?.recipient?.player_id}`);
        const allRC = document.querySelectorAll(`.red-card-${marker?.recipient?.player_id}`)
        const allGoals = document.querySelectorAll(`.goal-${marker?.recipient?.player_id}`)

        if (prevSubst) {
            if (allYC) {
                allYC.forEach(e => {
                    e.style.opacity = 1;
                })
            }
            if (allRC) {
                allRC.forEach(e => {
                    e.style.opacity = 1;
                })
            }
            if (allGoals) {
                allGoals.forEach(e => {
                    e.style.opacity = 1;
                })
            }
            if (pitchPlayerCreator) {
                pitchPlayerCreator.setAttribute('id', prevSubst?.recipient?.player_id);
                const [circle, number, name] = pitchPlayerCreator.children;

                if (circle) {
                    const textCircle = circle?.children?.[0];
                    if (textCircle) {
                        textCircle.innerHTML = `[${prevSubst?.recipient?.number}] ${prevSubst?.recipient?.name} ${prevSubst?.recipient?.surname}`
                    }
                }
                if (number) {
                    number.innerHTML = `${prevSubst?.recipient?.number}`
                }
                if (name) {
                    name.innerHTML = `${prevSubst?.recipient?.name?.[0]}. ${prevSubst?.recipient?.surname}`;
                }
            }
            return;
        } else {
            if (allYC) {
                allYC.forEach(e => {
                    e.style.opacity = 0;
                })
            }
            if (allRC) {
                allRC.forEach(e => {
                    e.style.opacity = 0;
                })
            }
            if (allGoals) {
                allGoals.forEach(e => {
                    e.style.opacity = 0;
                })
            }
            if (pitchPlayerRecipient) {
                pitchPlayerRecipient.setAttribute('id', marker?.creator?.player_id);
                const [circle, number, name] = pitchPlayerRecipient.children;

                if (circle) {
                    const textCircle = circle?.children?.[0];
                    if (textCircle) {
                        textCircle.innerHTML = `[${marker?.creator?.number}] ${marker?.creator?.name} ${marker?.creator?.surname}`
                    }
                }
                if (number) {
                    number.innerHTML = `${marker?.creator?.number}`
                }
                if (name) {
                    name.innerHTML = `${marker?.creator?.name?.[0]}. ${marker?.creator?.surname}`;
                }
            }
        }

        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="40"
                height="38"
                id={marker?.id}
                x={pos?.x}
                y={pos?.y - 30}
                version="1.1"
                viewBox="0 0 40 38"
            >
                <defs>
                    <filter
                        id="filter-1"
                        width="260%"
                        height="280%"
                        x="-70%"
                        y="-78.8%"
                        filterUnits="objectBoundingBox"
                    >
                        <feOffset
                            dx="2"
                            dy="2"
                            in="SourceAlpha"
                            result="shadowOffsetOuter1"
                        ></feOffset>
                        <feGaussianBlur
                            in="shadowOffsetOuter1"
                            result="shadowBlurOuter1"
                            stdDeviation="5"
                        ></feGaussianBlur>
                        <feColorMatrix
                            in="shadowBlurOuter1"
                            result="shadowMatrixOuter1"
                            values="0 0 0 0 0.0156862745 0 0 0 0 0.031372549 0 0 0 0 0.00784313725 0 0 0 0.2 0"
                        ></feColorMatrix>
                        <feMerge>
                            <feMergeNode in="shadowMatrixOuter1"></feMergeNode>
                            <feMergeNode in="SourceGraphic"></feMergeNode>
                        </feMerge>
                    </filter>
                </defs>
                <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
                    <g fillRule="nonzero" transform="translate(-378 -710)">
                        <g transform="translate(366 186)">
                            <g transform="translate(20 418)">
                                <g filter="url(#filter-1)" transform="translate(0 114)">
                                    <path
                                        fill="#31CA29"
                                        d="M19.775 13.48l-3.75-3.174a.618.618 0 00-.665-.088.637.637 0 00-.36.575v1.905h-1.046a2.48 2.48 0 01-2.12-1.194L7.786 4.926a4.952 4.952 0 00-4.24-2.387H0v2.54h3.546c.868 0 1.66.447 2.12 1.193l4.048 6.579a4.956 4.956 0 004.24 2.387H15v1.905a.626.626 0 00.625.635c.144 0 .286-.05.4-.148l3.75-3.174c.144-.12.225-.3.225-.488a.635.635 0 00-.225-.488z"
                                    ></path>
                                    <path
                                        fill="#F20A46"
                                        d="M19.775 3.321L16.025.146A.618.618 0 0015.36.06a.632.632 0 00-.36.574v1.905h-1.046a4.952 4.952 0 00-4.24 2.387l-.226.369L10.96 7.69l.873-1.419a2.48 2.48 0 012.12-1.193H15v1.905a.626.626 0 00.625.635c.144 0 .286-.051.4-.148l3.75-3.175c.144-.12.225-.3.225-.487a.635.635 0 00-.225-.488zM6.539 10.086l-.871 1.418a2.481 2.481 0 01-2.122 1.194H0v2.54h3.546c1.735 0 3.32-.893 4.24-2.387l.226-.369-1.473-2.396z"
                                    ></path>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </svg>
        )
    }

    const handleYellowCard = (marker, pos) => {
        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="20"
                className={`yellow-card-${marker?.creator?.player_id}`}
                viewBox="0 0 16 20"
                id={marker.id}
                x={pos?.x - 20}
                y={pos?.y + 5}
            >
                <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
                    <g transform="translate(-386 -628)">
                        <g transform="translate(366 186)">
                            <g transform="translate(20 418)">
                                <g transform="translate(0 24)">
                                    <path
                                        fill="#F5A028"
                                        fillRule="nonzero"
                                        d="M0 2C0 .895.897 0 2.004 0H9.22c1.107 0 2.005.895 2.005 2v13.2c0 1.105-.898 2-2.005 2H2.004A2.002 2.002 0 010 15.2V2z"
                                    ></path>
                                    <path
                                        fill="#040802"
                                        fillRule="nonzero"
                                        d="M5.812 15c0 2.761 2.244 5 5.01 5a5.005 5.005 0 005.011-5c0-2.761-2.243-5-5.01-5a5.005 5.005 0 00-5.01 5z"
                                    ></path>
                                    <path
                                        fill="#F5A028"
                                        d="M11.624 13a.8.8 0 00-1.603 0v2a.8.8 0 001.603 0v-2zm-.801 4.8a.8.8 0 10.001-1.602.8.8 0 00-.001 1.602z"
                                    ></path>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </svg>
        )
    }


    const handleRedCard = (marker, pos) => {
        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="20"
                id={marker.id}
                x={pos?.x - 35}
                y={pos?.y + 5}
                className={`red-card-${marker?.creator?.player_id}`}
                version="1.1"
                viewBox="0 0 16 20"
            >
                <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
                    <g transform="translate(-42 -651)">
                        <g transform="translate(20 54)">
                            <g transform="translate(20 56)">
                                <g transform="translate(2 541)">
                                    <path
                                        fill="#F20A46"
                                        fillRule="nonzero"
                                        d="M0 2C0 .895.897 0 2.004 0H9.22c1.107 0 2.005.895 2.005 2v13.2c0 1.105-.898 2-2.005 2H2.004A2.002 2.002 0 010 15.2V2z"
                                    ></path>
                                    <path
                                        fill="#040802"
                                        fillRule="nonzero"
                                        d="M5.812 15c0 2.761 2.244 5 5.01 5a5.005 5.005 0 005.011-5c0-2.761-2.243-5-5.01-5a5.005 5.005 0 00-5.01 5z"
                                    ></path>
                                    <path
                                        fill="#F20A46"
                                        d="M11.624 13a.8.8 0 00-1.603 0v2a.8.8 0 001.603 0v-2zm-.801 4.8a.8.8 0 10.001-1.602.8.8 0 00-.001 1.602z"
                                    ></path>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </svg>
        )
    }

    const handleAction = (
        marker,
        pos,
        prevMarker,
    ) => {
        switch (marker.action.id) {
            case 65:
            case 100:
                return handleGoal(marker, pos);
            case 206:
            case 86:
                return handleRedCard(marker, pos);
            case 55:
                return handleYellowCard(marker, pos);
            case 77:
                return handleSubstitution(marker, pos, prevMarker);
            default:
                return;
        }
    }

    const nextAction = () => {
        if (currentActions?.length === actionMarkers.length) {
            return
        }
        setDisableNextButton(false);

        if (currentActions?.length === 0) {
            setCurrentActions([actionMarkers[0]]);
            setCurrentTime(+actionMarkers[0].second)
            if (!changeSide && +actionMarkers[0].second > secondHalfSeconds) {
                setChangeSide(true);
            }
            // handleAction(actionMarkers[0], 'add')
            if (currentActions.length + 1 === actionMarkers.length) {
                setDisablePrevButton(false);
                setDisableNextButton(true);
                return
            }
        } else {
            setCurrentActions((prev) => [...prev, actionMarkers[prev.length]]);
            setCurrentTime(+actionMarkers[currentActions.length].second)

            if (!changeSide && +actionMarkers[currentActions.length].second > secondHalfSeconds) {
                setChangeSide(true);
            }
            // handleAction(actionMarkers[currentActions.length], 'add')

            if (currentActions.length + 1 === actionMarkers.length) {
                setDisableNextButton(true);
                return
            }
        }
        setDisablePrevButton(false);
    }

    const prevAction = () => {
        if (currentActions?.length === 1) {
            setCurrentActions([])
            setCurrentTime(0)
            setDisablePrevButton(true);
            setDisableNextButton(false);
            handleAction(actionMarkers[0], null, actionMarkers[0])

            if (changeSide && +actionMarkers[0].second < secondHalfSeconds) {
                setChangeSide(false);
            }
            return;
        }
        setDisablePrevButton(false);
        setDisableNextButton(false);
        const newArr = [...currentActions];
        const deletedAction = newArr.pop();

        setCurrentActions(newArr)
        setCurrentTime(+newArr[newArr.length - 1].second)
        deletedAction && handleAction(deletedAction, null, deletedAction)

        if (changeSide && +newArr[newArr.length - 1].second < secondHalfSeconds) {
            setChangeSide(false);
        }

    }

    const getPlayerActions = (player) => {
        if (player?.player?.player_id && currentActions?.length > 0) {
            const actionsWithCreator = currentActions.filter(item => item?.creator?.player_id === player?.player?.player_id);
            const actionsWithRecepient = currentActions.filter(item => item?.recipient?.player_id === player?.player?.player_id && (item?.action?.id !== 65 && item?.action?.id !== 100));

            if (actionsWithCreator?.length > 0 || actionsWithRecepient?.length > 0) {
                return [...actionsWithCreator, ...actionsWithRecepient]
            }
            return [];
        }
        return [];
    }

    const renderActions = (actions, pos) => {
        return actions.map(action => {
            return handleAction(action, pos);
        })
    }

    const handleOpenPlayerDetailsModal = (e, id) => {
        // need to get current player id after susbtitution
        const player_id = e?.target?.parentElement?.getAttribute('id') || id;
        const foundedData = savedPlayersData.find(item => item.player_id === +player_id);

        if (foundedData) {
            setSavedPlayer(foundedData);
        }
        setOpenPlayerDetailsModal(true);
        setSelectedPlayerId(+player_id);
    }

    const handleClosePlayerDetailsModal = (recivedData) => {
        setOpenPlayerDetailsModal(false);
        setSelectedPlayerId(null);
        setSavedPlayer(null);

        if (recivedData) {
            const newData = [...savedPlayersData];
            const foundedDataIdx = newData?.findIndex(item => item?.player_id === recivedData?.player_id);
            if (foundedDataIdx >= 0) {
                newData[foundedDataIdx] = recivedData;
            } else {
                newData.push(recivedData);
            }
            setSavedPlayersData(newData);
        }
    }

    const actionsPlayer = useMemo(() => {
        if (actionMarkers?.length > 0 && selectedPlayerId) {
            return actionMarkers.filter(item => item?.creator?.player_id === selectedPlayerId || item?.recipient?.player_id === selectedPlayerId)
        } else {
            return [];
        }
    }, [actionMarkers, selectedPlayerId])

    if (!open) return null;
    

    return (
        <FormationOverlay>
            <FormationModal>
                <CloseIcon src={close} alt={close} onClick={handleClose} />
                <FormationContainer>
                    <h2>Formation</h2>
                    {
                        loadingFormationMarkers
                            ? (
                                <Oval
                                    visible={true}
                                    height="80"
                                    width="80"
                                     secondaryColor={
                                        localStorage.getItem("theme") === "light"
                                        ? "#4E5255"
                                        : "#CFDAE6"
                                    }
                                    color={localStorage.getItem("theme") === "light" ? "#2363F7" : "#4fa94d"}
                                    ariaLabel="oval-loading"
                                    wrapperStyle={{
                                        display: 'flex',
                                        justifyContent: 'center'
                                    }}
                                    wrapperClass=""
                                />
                            )
                            : (
                                <>
                                    <FormationMatchTitle>
                                        <p>{matchInfo?.season?.title}</p>
                                        <span>Match Played on <b>{matchInfo?.date && (new Date(matchInfo.date)).toLocaleDateString("en-US", { year: 'numeric', month: 'long', day: 'numeric' })}</b></span>
                                    </FormationMatchTitle>
                                    <FormationScoreContainer>
                                        <FormationTeamTitle>
                                            {
                                                changeSide ? (
                                                    <>
                                                        <span>
                                                            <img src={`https://platform.smrtstats.com:8888${matchInfo?.away_team?.logo}`} alt="" />
                                                        </span>
                                                        <b title={matchInfo?.away_team?.name}>{matchInfo?.away_team?.name}</b>
                                                    </>
                                                ) : (
                                                    <>
                                                        <span>
                                                            <img src={`https://platform.smrtstats.com:8888${matchInfo?.home_team?.logo}`} alt="" />
                                                        </span>
                                                        <b title={matchInfo?.home_team?.name}>{matchInfo?.home_team?.name}</b>
                                                    </>
                                                )
                                            }

                                        </FormationTeamTitle>
                                        <FormationScore>
                                            {changeSide ? match?.score?.split(':')?.reverse()?.join(' : ') : match?.score?.split(':')?.join(' : ')}
                                        </FormationScore>
                                        <FormationTeamTitle>
                                            {changeSide ? (
                                                <>
                                                    <b title={matchInfo?.home_team?.name}>{matchInfo?.home_team?.name}</b>
                                                    <span>
                                                        <img src={`https://platform.smrtstats.com:8888${matchInfo?.home_team?.logo}`} alt="" />
                                                    </span>
                                                </>
                                            ) : (
                                                <>
                                                    <b title={matchInfo?.away_team?.name}>{matchInfo?.away_team?.name}</b>
                                                    <span>
                                                        <img src={`https://platform.smrtstats.com:8888${matchInfo?.away_team?.logo}`} alt="" />
                                                    </span>
                                                </>
                                            )}

                                        </FormationTeamTitle>
                                    </FormationScoreContainer>
                                    <svg
                                        id={"formation"}
                                        width={fieldWidth + 2 * fieldPadding}
                                        height={fieldHeight + 2 * fieldPadding}
                                        viewBox={`0 0 ${fieldWidth + 2 * fieldPadding} ${fieldHeight + 2 * fieldPadding
                                            }`}
                                        preserveAspectRatio="xMidYMid meet"
                                        style={{ borderRadius: 3 }}
                                    >
                                        {/* Background Image */}
                                        <image
                                            href={backgroundImage}
                                            x={fieldPadding}
                                            y={fieldPadding}
                                            width={fieldWidth}
                                            height={fieldHeight}
                                            preserveAspectRatio="xMidYMid slice"
                                        />

                                        {leftSidePlayers?.map(item => {
                                            const pos = convertCoordinatesWithPadding(item?.zone?.coords_pos?.x, item?.zone?.coords_pos?.y)
                                            const actions = getPlayerActions(item)
                                            return (
                                                <g
                                                    key={item.id}
                                                    id={item.player?.player_id}
                                                    onClick={(e) => handleOpenPlayerDetailsModal(e, item?.player?.player_id)}
                                                    className='player'
                                                >
                                                    <circle
                                                        className="tactic"
                                                        cx={pos.x}
                                                        cy={pos.y}
                                                        r="18.133333333333333"
                                                        style={{ fill: item.color }}
                                                    >
                                                        <title>[{item?.player?.number}] {item?.player?.name}{item?.player?.surname}</title>
                                                    </circle>
                                                    <text
                                                        textAnchor="middle"
                                                        fill={item.color_number}
                                                        x={pos.x}
                                                        y={pos.y + 5}
                                                        style={{ fontSize: "14.2857px", fontFamily: "SpaceGrotesk", fontWeight: 600, pointerEvents: "none" }}
                                                    >
                                                        {item?.player?.number}
                                                    </text>
                                                    <text
                                                        textAnchor="middle"
                                                        fill={'#fff'}
                                                        x={pos.x}
                                                        y={pos.y + 40}
                                                        style={{ fontSize: "14.2857px", fontFamily: "SpaceGrotesk", fontWeight: 500, pointerEvents: "none" }}
                                                    >
                                                        <tspan x={pos.x} style={{ fontSize: "14.2857px", fontFamily: "SpaceGrotesk", fontWeight: 500, pointerEvents: "none" }}>{item?.player?.name?.slice(0, 5)}...</tspan>
                                                        <tspan x={pos.x} dy="1.2em" style={{ fontSize: "14.2857px", fontFamily: "SpaceGrotesk", fontWeight: 500, pointerEvents: "none" }}>{item?.player?.surname?.slice(0, 5)}...</tspan>
                                                        
                                                    </text>
                                                    <>
                                                        {renderActions(actions, pos)}
                                                    </>
                                                </g>
                                            )
                                        })}
                                        {rightSidePlayers?.map(item => {
                                            const pos = convertCoordinatesWithPadding(item?.zone?.coords_pos?.x, item?.zone?.coords_pos?.y)
                                            const actions = getPlayerActions(item)
                                            return (
                                                <g
                                                    key={item.id}
                                                    id={item.player?.player_id}
                                                    className='player'
                                                    onClick={(e) => handleOpenPlayerDetailsModal(e, item?.player?.player_id)}
                                                >
                                                    <circle
                                                        className="tactic"
                                                        cx={pos.x}
                                                        cy={pos.y}
                                                        r="18.133333333333333"
                                                        style={{ fill: item.color }}
                                                    >
                                                        <title>[{item?.player?.number}] {item?.player?.name}  {item?.player?.surname}</title>
                                                    </circle>
                                                    <text
                                                        textAnchor="middle"
                                                        fill={item.color_number}
                                                        x={pos.x}
                                                        y={pos.y + 5}
                                                        style={{ fontSize: "14.2857px", fontFamily: "SpaceGrotesk", fontWeight: 600, pointerEvents: "none" }}
                                                    >
                                                        {item?.player?.number}
                                                    </text>
                                                    <text
                                                        textAnchor="middle"
                                                        fill={'#fff'}
                                                        x={pos.x}
                                                        y={pos.y + 40}
                                                        style={{ fontSize: "14.2857px", fontFamily: "SpaceGrotesk", fontWeight: 500, pointerEvents: "none" }}
                                                    >
                                                         <tspan x={pos.x} style={{ fontSize: "14.2857px", fontFamily: "SpaceGrotesk", fontWeight: 500, pointerEvents: "none" }}>{item?.player?.name?.slice(0, 5)}...</tspan>
                                                        <tspan x={pos.x} dy="1.2em" style={{ fontSize: "14.2857px", fontFamily: "SpaceGrotesk", fontWeight: 500, pointerEvents: "none" }}>{item?.player?.surname?.slice(0, 5)}...</tspan>
                                                    </text>
                                                    <>
                                                        {renderActions(actions, pos)}
                                                    </>
                                                </g>
                                            )
                                        })}
                                    </svg>
                                    <ChangeFormationTimeContainer>
                                        <p>{changeSide ? awayTactic : homeTactic}</p>
                                        <ChangeFormationTime
                                            disableNextButton={disableNextButton || loadingMatchMarkers || loadingFormationMarkers}
                                            disablePrevButton={disablePrevButton || loadingMatchMarkers || loadingFormationMarkers}
                                            nextAction={nextAction}
                                            prevAction={prevAction}
                                            time={currentTime}
                                            loading={loadingMatchMarkers || loadingFormationMarkers}
                                        />
                                        <p>{changeSide ? homeTactic : awayTactic}</p>
                                    </ChangeFormationTimeContainer>
                                </>
                            )
                    }
                </FormationContainer>
            </FormationModal>

            <PlayerDetailsModal
                open={openPlayerDetailsModal}
                onClose={(data) => handleClosePlayerDetailsModal(data)}
                savedPlayer={savedPlayer}
                match={matchInfo}
                player={selectedPlayerId}
                actionsPlayer={actionsPlayer}
            />
        </FormationOverlay>
    )
}
